/**
 * SIDC MMS Core
 * Our Endpoints are powered by .Net Core, Onion Architecture, CQRS with MediatR Library,Entity Framework Core - Code First,Repository Pattern - Generic, Swagger UI, API Versioning,Fluent Validation and Automapper.
 *
 * OpenAPI spec version: v1
 * Contact: sidcmisdepartment@gmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UpdateApplicantBasicInformationCommand { 
    applicantNumber?: string;
    isSidcEmployee?: boolean;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    suffixName?: string;
    emailAddress?: string;
    contactNumber?: string;
    birthday?: Date;
    age?: number;
    religion?: string;
    educationalAttainment?: string;
    numberOfDependents?: number;
    spouseName?: string;
    spouseOccupation?: string;
    dateOfMarriage?: Date;
    fatherName?: string;
    fatherOccupation?: string;
    motherMaidenName?: string;
    motherOccupation?: string;
    telephoneNumber?: string;
    tin?: string;
    street?: string;
    barangay?: string;
    city?: string;
    province?: string;
    zipCode?: string;
    civilStatus?: string;
    genderId?: number;
    applicationStatusId?: number;
    regionId?: number;
    profilePicture?: string;
    representativeOf?: string;
    remarks?: string;
    generatedBy?: string;
    dateOfMedical?: Date;
}