import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { TranslateModule } from '@ngx-translate/core';
import { CoreCommonModule } from '@core/common.module';
import { AdminGuard } from 'app/common/guards/admin.guard';
import { MemberGuard } from 'app/common/guards/member.guard';
import { ApplicantDetailsResolver } from 'app/common/resolvers/applicant-details.resolver';
import { ApplicantRequirementService, ApplicantService, IdentityService, LoansReceivableService, SubscriptionService } from 'app/api';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
  },
  {
    path: 'subscription',
    loadChildren: () => import('./member/subscription/subscription.module').then(m => m.SubscriptionModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AdminGuard]
  },
  {
    path: '',
    loadChildren: () => import('./member/member.module').then(m => m.MemberModule),
    resolve: { ApplicantDetails: ApplicantDetailsResolver },
    canActivate: [MemberGuard]
  },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes),
    ContentHeaderModule,
    TranslateModule,
    CoreCommonModule,
  ],
  providers: [ ApplicantService, ApplicantRequirementService, SubscriptionService, IdentityService, LoansReceivableService ]
})
export class MainModule { }
