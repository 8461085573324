import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AccountReceivableService } from './api/accountReceivable.service';
import { AdminService } from './api/admin.service';
import { AnnouncementService } from './api/announcement.service';
import { ApplicantService } from './api/applicant.service';
import { ApplicantChangeRequestService } from './api/applicantChangeRequest.service';
import { ApplicantRequirementService } from './api/applicantRequirement.service';
import { ApplicationConfigurationService } from './api/applicationConfiguration.service';
import { BankAccountService } from './api/bankAccount.service';
import { BranchService } from './api/branch.service';
import { ChangeRequestService } from './api/changeRequest.service';
import { DataExtractionAddressWithRemarksService } from './api/dataExtractionAddressWithRemarks.service';
import { DataExtractionAllPendingApplicantsService } from './api/dataExtractionAllPendingApplicants.service';
import { DataExtractionForPreApprovalMembersService } from './api/dataExtractionForPreApprovalMembers.service';
import { DataExtractionMemberIdStatusService } from './api/dataExtractionMemberIdStatus.service';
import { DataExtractionMembersDataService } from './api/dataExtractionMembersData.service';
import { DataExtractionPendingRegularMemberService } from './api/dataExtractionPendingRegularMember.service';
import { DataExtractionSourceOfIncomeService } from './api/dataExtractionSourceOfIncome.service';
import { DataExtractionTemporaryCodeService } from './api/dataExtractionTemporaryCode.service';
import { DigitalIdService } from './api/digitalId.service';
import { DividendService } from './api/dividend.service';
import { EmployeeService } from './api/employee.service';
import { EventService } from './api/event.service';
import { EventAttendeesService } from './api/eventAttendees.service';
import { GAMService } from './api/gAM.service';
import { GenderService } from './api/gender.service';
import { IdentityService } from './api/identity.service';
import { InvestmentService } from './api/investment.service';
import { LeadsService } from './api/leads.service';
import { LoansReceivableService } from './api/loansReceivable.service';
import { OldRequirementsService } from './api/oldRequirements.service';
import { PsgcService } from './api/psgc.service';
import { RegionService } from './api/region.service';
import { RegionJurisdictionService } from './api/regionJurisdiction.service';
import { ShareCapitalService } from './api/shareCapital.service';
import { StandardEntryService } from './api/standardEntry.service';
import { SubscriptionService } from './api/subscription.service';
import { VoucherService } from './api/voucher.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AccountReceivableService,
    AdminService,
    AnnouncementService,
    ApplicantService,
    ApplicantChangeRequestService,
    ApplicantRequirementService,
    ApplicationConfigurationService,
    BankAccountService,
    BranchService,
    ChangeRequestService,
    DataExtractionAddressWithRemarksService,
    DataExtractionAllPendingApplicantsService,
    DataExtractionForPreApprovalMembersService,
    DataExtractionMemberIdStatusService,
    DataExtractionMembersDataService,
    DataExtractionPendingRegularMemberService,
    DataExtractionSourceOfIncomeService,
    DataExtractionTemporaryCodeService,
    DigitalIdService,
    DividendService,
    EmployeeService,
    EventService,
    EventAttendeesService,
    GAMService,
    GenderService,
    IdentityService,
    InvestmentService,
    LeadsService,
    LoansReceivableService,
    OldRequirementsService,
    PsgcService,
    RegionService,
    RegionJurisdictionService,
    ShareCapitalService,
    StandardEntryService,
    SubscriptionService,
    VoucherService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
