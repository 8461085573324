export * from './report.service';
import { ReportService } from './report.service';
export * from './reportForBilling.service';
import { ReportForBillingService } from './reportForBilling.service';
export * from './reportForMembers.service';
import { ReportForMembersService } from './reportForMembers.service';
export * from './reportMembersWithVouchers.service';
import { ReportMembersWithVouchersService } from './reportMembersWithVouchers.service';
export * from './reportPaymentForVerify.service';
import { ReportPaymentForVerifyService } from './reportPaymentForVerify.service';
export * from './reportPendingApplicantsWithPmes.service';
import { ReportPendingApplicantsWithPmesService } from './reportPendingApplicantsWithPmes.service';
export * from './reportPmesAttendance.service';
import { ReportPmesAttendanceService } from './reportPmesAttendance.service';
export * from './reportReferral.service';
import { ReportReferralService } from './reportReferral.service';
export * from './reportRequirements.service';
import { ReportRequirementsService } from './reportRequirements.service';
export const APIS = [ReportService, ReportForBillingService, ReportForMembersService, ReportMembersWithVouchersService, ReportPaymentForVerifyService, ReportPendingApplicantsWithPmesService, ReportPmesAttendanceService, ReportReferralService, ReportRequirementsService];
