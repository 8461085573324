// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  apiUrl: 'https://api.emembership.sidc.coop',
  readApiUrl: 'https://read.api.emembership.sidc.coop',
  reportApiUrl: 'https://api.reports.sidc.coop',
  apiVersion: '1',
  amountPerShare: 100,
  membershipFee: 150,
  regularMemberMaxAge: 65,
  regularMemberMedicalMaxAge: 50,
  projectCodeForReportServer: 'MMS',
  identity: {
    username: 'mms-mis',
    password: '*^M3mb3r5h1p^*'
  },
  apiUrlForIdentity: 'https://api.identity.sidc.coop',
  apiVersionForIdentity: '1'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
