import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { Observable } from 'rxjs';
import { Role } from '../models/role';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router, private coreMenuService: CoreMenuService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const currentUser = this.auth.currentUserValue;
    let tree: UrlTree;

    if (currentUser && this.auth.isAuthenticated) {
      if (route.data.roles && !route.data.roles.includes(this.auth.currentUserValue.roles[0])) {
        this.router.navigate(['/error/unauthorized']);
        return false;
      }

      const path =  route['_urlSegment'].segments &&  route['_urlSegment'].segments.length > 0 ?  route['_urlSegment'].segments[0].path : ''; 
      const filteredMenu = this.coreMenuService.getCurrentMenu().find(m => m.id === path);
      if (Object.values(Role).filter(r => r === currentUser.roles[0]).length === 0) {
        this.router.navigate(['/error/unauthorized']);
        
        return false;
      } else if (filteredMenu && filteredMenu.role.filter(r => r === currentUser.roles[0]).length === 0) {
        this.router.navigate(['/error/unauthorized']);
        return false;
      }

      return true;
    }
   
    this.auth.clearSession();
    this.router.navigate(['/auth/login']);
    return false;
  }
  
}
