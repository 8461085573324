import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ActivatedRoute
} from '@angular/router';
import { ApplicantService } from 'app/read-api';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicantDetailsResolver implements Resolve<boolean> {
  constructor(
    private applicantService: ApplicantService,
    private authService: AuthService,
    private router: Router
  ) {}
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const applicantionNumber = (route.params && route.params.id ? route.params.id: this.authService.currentUserValue.applicant.applicantNumber);
    if (!applicantionNumber) return of(null);;
    return this.applicantService.apiVversionMembershipApplicantTabDetailsGet(environment.apiVersion, applicantionNumber).pipe(
      catchError(error => {
        this.router.navigate(['/error/internal-server']);
        return of(null);
      })
    );
  }
}
