import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'spaceCase'
})
export class SpaceCasePipe implements PipeTransform {

    transform(value: string): string {
        if(!value){return '';}

        const spacedValue = value.replace(/([A-Z])/g, ' $1').trim();

        return spacedValue.replace(/\bAnd\b/g, 'and');
    }
}