export * from './accountReceivable.service';
import { AccountReceivableService } from './accountReceivable.service';
export * from './admin.service';
import { AdminService } from './admin.service';
export * from './announcement.service';
import { AnnouncementService } from './announcement.service';
export * from './applicant.service';
import { ApplicantService } from './applicant.service';
export * from './applicantChangeRequest.service';
import { ApplicantChangeRequestService } from './applicantChangeRequest.service';
export * from './applicantRequirement.service';
import { ApplicantRequirementService } from './applicantRequirement.service';
export * from './applicationConfiguration.service';
import { ApplicationConfigurationService } from './applicationConfiguration.service';
export * from './bankAccount.service';
import { BankAccountService } from './bankAccount.service';
export * from './branch.service';
import { BranchService } from './branch.service';
export * from './changeRequest.service';
import { ChangeRequestService } from './changeRequest.service';
export * from './dataExtractionAddressWithRemarks.service';
import { DataExtractionAddressWithRemarksService } from './dataExtractionAddressWithRemarks.service';
export * from './dataExtractionAllPendingApplicants.service';
import { DataExtractionAllPendingApplicantsService } from './dataExtractionAllPendingApplicants.service';
export * from './dataExtractionForPreApprovalMembers.service';
import { DataExtractionForPreApprovalMembersService } from './dataExtractionForPreApprovalMembers.service';
export * from './dataExtractionMemberIdStatus.service';
import { DataExtractionMemberIdStatusService } from './dataExtractionMemberIdStatus.service';
export * from './dataExtractionMembersData.service';
import { DataExtractionMembersDataService } from './dataExtractionMembersData.service';
export * from './dataExtractionPendingRegularMember.service';
import { DataExtractionPendingRegularMemberService } from './dataExtractionPendingRegularMember.service';
export * from './dataExtractionSourceOfIncome.service';
import { DataExtractionSourceOfIncomeService } from './dataExtractionSourceOfIncome.service';
export * from './dataExtractionTemporaryCode.service';
import { DataExtractionTemporaryCodeService } from './dataExtractionTemporaryCode.service';
export * from './dividend.service';
import { DividendService } from './dividend.service';
export * from './employee.service';
import { EmployeeService } from './employee.service';
export * from './event.service';
import { EventService } from './event.service';
export * from './eventAttendees.service';
import { EventAttendeesService } from './eventAttendees.service';
export * from './gAM.service';
import { GAMService } from './gAM.service';
export * from './gender.service';
import { GenderService } from './gender.service';
export * from './identity.service';
import { IdentityService } from './identity.service';
export * from './investment.service';
import { InvestmentService } from './investment.service';
export * from './leads.service';
import { LeadsService } from './leads.service';
export * from './loansReceivable.service';
import { LoansReceivableService } from './loansReceivable.service';
export * from './paymentVerification.service';
import { PaymentVerificationService } from './paymentVerification.service';
export * from './psgc.service';
import { PsgcService } from './psgc.service';
export * from './region.service';
import { RegionService } from './region.service';
export * from './regionJurisdiction.service';
import { RegionJurisdictionService } from './regionJurisdiction.service';
export * from './shareCapital.service';
import { ShareCapitalService } from './shareCapital.service';
export * from './standardEntry.service';
import { StandardEntryService } from './standardEntry.service';
export * from './voucher.service';
import { VoucherService } from './voucher.service';
export const APIS = [AccountReceivableService, AdminService, AnnouncementService, ApplicantService, ApplicantChangeRequestService, ApplicantRequirementService, ApplicationConfigurationService, BankAccountService, BranchService, ChangeRequestService, DataExtractionAddressWithRemarksService, DataExtractionAllPendingApplicantsService, DataExtractionForPreApprovalMembersService, DataExtractionMemberIdStatusService, DataExtractionMembersDataService, DataExtractionPendingRegularMemberService, DataExtractionSourceOfIncomeService, DataExtractionTemporaryCodeService, DividendService, EmployeeService, EventService, EventAttendeesService, GAMService, GenderService, IdentityService, InvestmentService, LeadsService, LoansReceivableService, PaymentVerificationService, PsgcService, RegionService, RegionJurisdictionService, ShareCapitalService, StandardEntryService, VoucherService];
