import { NgModule } from '@angular/core';
import { ReplaceLineBreaksPipe } from './replace-line-breaks.pipe';
import { ShortNumberPipe } from './short-number.pipe';
import { SpaceCasePipe } from './space-case-pipe';

@NgModule({
  declarations: [ReplaceLineBreaksPipe, ShortNumberPipe, SpaceCasePipe],
  imports: [],
  exports: [ReplaceLineBreaksPipe, ShortNumberPipe, SpaceCasePipe]
})
export class PipesModule {}
