export const locale = {
    lang: 'tl',
    data: {
      REGISTRATION: {
        PERSONAL_INFO: {
            RELIGION: 'Relihiyon',
            CIVIL_STATUS: 'Katayuang Sibil',
            NO_OF_DEPENDENTS: 'Bilang ng Dependents',
            TELEPHONE: 'Telepono',
            BIRTHDATE: 'Kapanganakan',
            AGE: 'Age',
            SPOUSE_NAME: 'Pangalan ng Asawa',
            SPOUSE_OCCUPATION: 'Trabaho ng Asawa',
            DATE_OF_MARRIAGE: 'Petsa ng Kasal',
            FATHER_NAME: 'Father\'s Name',
            FATHER_OCCUPATION: 'Father\'s Occupation',
            MOTHER_MAIDEN_NAME: 'Mother\'s Maiden Name',
            MOTHER_OCCUPATION: 'Mother\'s Occupation'
        },
        WORK: {
            OCCUPATION: 'Hanapbuhay',
            SALARY: 'Salary',
            OFW: 'Kung OFW'
        },
        WAIVER: {
          MEMBER_AGREEMENT: 'Kasunduan Pagsapi at Subskripsyon sa Kapital',
          MEMBERSHIP_STATEMENT: 'Pahayag sa Pagsapi',
          WAIVER: 'Kasulatan Hingil sa Hindi Pagtanggap ng Benepisyong Medikal'
        },
        DECLARATION: {
            TAB_TITLE: 'Deklarasyon'
        }
      },
      MENU: {
        HOME: 'Home',
        DASHBOARD: 'Dashboard',
        PURCHASES: 'Purchases',
        SHARE_CAPITAL: 'Share Capital',
        MEMBER_TRANSACTIONS: 'Transactions',
        LOANS: 'Loans',
        INVESTMENTS: 'Investments',
        CHANGE_REQUEST: 'Change Request',
        CONTACT_US: 'Contact Us',
        APPLICANTS: 'Applicants',
        LEADS: 'Leads',
        MEMBERS: 'Members',
        EVENTS: 'Seminars',
        VOUCHERS: 'Vouchers',
        SETTINGS: 'Settings',
        ANNOUNCEMENTS: 'Announcements',
        REPORTS: 'Reports',
        USERS: 'Users',
        SETTINGS_LANGUAGE: 'Language',
        SETUP: 'Setup',
        SUPER_ADMIN_SETUP: 'Super Admin Setup',
        FINANCIALS: 'Financials',
        DIVIDEND: 'Dividend'
      },
      SAMPLE: {
        AWESOME: 'Create Awesome 🙌'
      }
    }
  }