import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { ReportService } from './api/report.service';
import { ReportForBillingService } from './api/reportForBilling.service';
import { ReportForMembersService } from './api/reportForMembers.service';
import { ReportMembersWithVouchersService } from './api/reportMembersWithVouchers.service';
import { ReportPaymentForVerifyService } from './api/reportPaymentForVerify.service';
import { ReportPendingApplicantsWithPmesService } from './api/reportPendingApplicantsWithPmes.service';
import { ReportPmesAttendanceService } from './api/reportPmesAttendance.service';
import { ReportReferralService } from './api/reportReferral.service';
import { ReportRequirementsService } from './api/reportRequirements.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    ReportService,
    ReportForBillingService,
    ReportForMembersService,
    ReportMembersWithVouchersService,
    ReportPaymentForVerifyService,
    ReportPendingApplicantsWithPmesService,
    ReportPmesAttendanceService,
    ReportReferralService,
    ReportRequirementsService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
